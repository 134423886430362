/* Dark: #4b4bd1 */
/* Primary: #7B77FF */
@font-face {
  font-family: 'Vim';
  src: url("../fonts/VimSM-Heavy.woff2") format("woff2"), url("../fonts/VimSM-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Vim';
  src: url("../fonts/VimSM-Heavy-Oblique.woff2") format("woff2"), url("../fonts/VimSM-Heavy-Oblique.woff") format("woff");
  font-weight: 900;
  font-style: italic; }

html {
  /* background-image: url("../images/map.svg"); */
  background-size: 50vw;
  background-repeat: no-repeat;
  background-position: top right;
  font-family: 'Roboto', sans-serif;
  background-color: #7B77FF;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Vim';
  font-style: italic;
  line-height: normal; }

p {
  line-height: normal;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }

.toggle__label {
  font-size: 12px;
  color: #999999;
  transition: 0.2s color; }

.toggle__label--active {
  color: black; }

.toggle {
  display: flex;
  align-items: center; }

.toggle__background {
  width: 40px;
  border-radius: 20px;
  background-color: #68ff95;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  margin: 0px 8px; }

.toggle__indicator {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px;
  transition: all 0.2s ease 0s; }

/* .toggle--active .toggle__background {
  background-color: #68ff95;
} */
.toggle--active .toggle__indicator {
  transform: translateX(20px); }

.formbox {
  padding: 20px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  max-width: 360px; }

.main {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
  left: 50%;
  top: 50%;
  z-index: 1;
  padding: 40px 0; }

.main-inner {
  padding: 0 20px; }

@media (min-width: 400px) {
  .main {
    max-width: 500px; } }

.header {
  text-align: center;
  color: #fff;
  margin-bottom: 10px; }

.header__icon {
  margin-bottom: 20px;
  width: 40px;
  height: 40px; }

.title {
  font-size: 28px; }

.input {
  font-size: 12px;
  padding: 10px;
  border: 1px solid #7B77FF;
  width: 100%; }

.form-element {
  margin: 15px 0; }

.map {
  width: 100vw;
  height: 100vh;
  z-index: 0;
  opacity: 0.1;
  overflow: hidden; }

.map-background {
  transform-origin: center;
  width: 100%;
  min-width: 600px;
  margin: 0 auto;
  animation: scale 30s cubic-bezier(0.55, 0.09, 0.68, 0.53) infinite; }

@keyframes scale {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(2); }
  100% {
    transform: scale(1); } }

.button {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  font-family: 'Vim';
  font-size: 18px;
  font-weight: 900;
  font-style: oblique;
  color: #fff;
  -webkit-appearance: none;
  background-color: #7b77ff;
  width: 100%;
  padding: 10px 0;
  border: none;
  transition: all 0.2s; }

.button:hover {
  opacity: 0.8; }

.link {
  font-weight: 700;
  color: inherit; }

.hide {
  display: none; }

p:not(.hide) + p {
  margin-top: 1em; }

.text--error {
  color: red; }

.footer {
  color: white;
  text-align: center;
  margin-top: 20px; }

.app-links {
  display: flex;
  margin: 10px 0 40px;
  justify-content: center;
  align-items: center; }

.app-links__item {
  max-width: 150px; }

.app-links__image {
  max-width: 100%; }
